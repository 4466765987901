import { ref } from '@vue/composition-api'

import axiosClient from '@/helpers/axios'
import axiosOauthClient from '@/helpers/axiosUtilities'

import useAlertNotification from '@/services/notification/useAlert'

export default function useAbonnements() {
  const { errorToast, successToast } = useAlertNotification()
  const abonnement = ref([])
  const loader = ref(false)
  const abonnementProcess = ref(false)
  const abonnementSuccess = ref(false)
  const abonnements = ref([])
  const errors = ref('')

  // Liste des abonnements
  const getAbonnements = async () => {
    loader.value = true
    await axiosClient.get('/abonnements').then(response => {
      if (response.data.success === true) {
        loader.value = false
        abonnements.value = response.data.data
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Liste des abonnements
  const checkAbonnement = async id => {
    loader.value = true
    await axiosClient.get(`/abonnements/check/${id}`).then(response => {
      if (response.data.success === true) {
        loader.value = false
        console.log(response.data.data)
      }
    })
      .catch(() => {
        loader.value = false
      })
  }

  // Obtenir un abonnement
  const getAbonnement = async slug => {
    const response = await axiosClient.get(`/abonnements/${slug}`)
    abonnement.value = response.data.data
  }

  // Obtenir un abonnement
  const getClientAccess = async () => {
    const data = {
      name: 'Client Name',
      redirect: 'http://example.com/callback',
    }
    const response = await axiosOauthClient.post('/oauth/clients', data)
    console.log(response)
  }
  // Ajouter un abonnement
  const createAbonnement = async data => {
    errors.value = ''
    abonnementProcess.value = true
    await axiosClient.post('/abonnements', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Enregistrement', response.data.message)
          abonnementSuccess.value = true
          abonnementProcess.value = false
          getAbonnements()
        }
      })
      .catch(error => {
        abonnementProcess.value = false
        abonnementSuccess.value = false
        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }
  const reabonnement = async data => {
    errors.value = ''
    abonnementProcess.value = true
    await axiosClient.post('/reabonnement', data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          abonnementSuccess.value = true
          abonnementProcess.value = false
          getAbonnements()
        }
      })
      .catch(error => {
        abonnementProcess.value = false
        abonnementSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  // Modifier un abonnement
  const updateAbonnement = async data => {
    errors.value = ''
    abonnementProcess.value = true
    await axiosClient.patch(`/abonnements/${data.id}`, data)
      .then(response => {
        if (response.data.success === true) {
          successToast('Modification', response.data.message)
          abonnementSuccess.value = true
          abonnementProcess.value = false
          getAbonnements()
        }
      })
      .catch(error => {
        abonnementProcess.value = false
        abonnementSuccess.value = false

        if (error.response.status === 403) {
          errorToast('403 Forbidden', 'Vous n\'êtes pas autorisé(e) à effectuer cette action')
        } else {
          errorToast('Oups! Erreur', error.response.data.message)
          errors.value = error.response.data.errors
        }
      })
  }

  return {
    errors,
    abonnement,
    abonnements,
    getAbonnements,
    abonnementProcess,
    createAbonnement,
    updateAbonnement,
    getAbonnement,
    reabonnement,
    loader,
    getClientAccess,
    checkAbonnement,
    abonnementSuccess,
  }
}
